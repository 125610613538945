import React from "react";

import * as Styled from "./styles";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { ReactComponent as Icon1 } from "shared/assets/svg/first.svg";
import { ReactComponent as Icon2 } from "shared/assets/svg/second.svg";

const Onboarding: React.FC = () => {
  const [boarding, setBoarding] = React.useState<"1" | "2">("1");
  const navigate = useNavigate();

  return (
    <Styled.Box>
      {boarding === "1" ? (
        <>
          <Icon1 />
          <Styled.Texts>
            <Styled.Title>
              Visualize sua agenda <br />
              diária de atendimentos
            </Styled.Title>
            <Styled.Subtitle>
              Com o app OrthoDontic Dentistas, seu dia-a-dia
              <br /> fica mais organizado.
            </Styled.Subtitle>
          </Styled.Texts>
          <Button fullWidth onClick={() => setBoarding("2")}>
            Próximo
          </Button>
        </>
      ) : (
        <>
          <Icon2 />
          <Styled.Texts>
            <Styled.Title>
              Faclidade para acessar <br /> fichas clínicas
            </Styled.Title>
            <Styled.Subtitle>
              Acesse rapidamente o histórico de atendimento
              <br /> dos seus pacientes.
            </Styled.Subtitle>
          </Styled.Texts>
          <Button fullWidth onClick={() => navigate("/login")}>
            COMEÇAR
          </Button>
        </>
      )}
    </Styled.Box>
  );
};

export { Onboarding };
