import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "shared/providers/auth";

interface Alert {
  key: string; // Adiciona a chave
  message: string; // Mensagem correspondente
}

interface QuestionModalProps {
  questions: Alert[]; // Atualiza o tipo para Alert[]
  onClose: (
    responses: { id: string; description: string; message: string }[]
  ) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const QuestionModal: React.FC<QuestionModalProps> = ({
  questions,
  onClose,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { responses, setResponses } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleResponse = (response: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    setResponses([
      ...responses,
      {
        id: currentQuestion.key, // Usar a chave como ID
        description: response,
        message: currentQuestion.message,
      },
    ]);

    if (currentQuestionIndex < questions.length - 1) {
      setLoading(true);
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setLoading(false);
      }, 1000); // Atraso de 1 segundo
    } else {
      onClose(responses);
    }
  };

  const handleCamera = () => {
    //@ts-ignore
    if (window && window.ReactNativeWebView) {
      //call rn to play audio
      //@ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "openCamera",
          value: "",
        })
      );
    }
    handleResponse("Sim");
  };

  const closeFake = () => {
    if (questions[currentQuestionIndex].key === "evolution") {
      onClose(responses);
    }
  };

  return (
    <Modal open={true} onClose={closeFake}>
      <Box sx={style}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" component="h2">
              {questions[currentQuestionIndex].message}
            </Typography>
            {questions[currentQuestionIndex].key === "evolution" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCamera}
                  fullWidth
                >
                  Tirar Foto
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleResponse("Não")}
                  fullWidth
                  className="negative"
                >
                  Não
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleResponse("Sim")}
                  fullWidth
                >
                  Sim
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleResponse("Não")}
                  fullWidth
                  className="negative"
                >
                  Não
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default QuestionModal;
