import React from "react";
import * as Styled from "./styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFirstConsult } from "shared/providers/FirstConsult";

import { Odontograma } from "components/Odontograma";
import { useAuth } from "shared/providers/auth";
import { materialOptions, numbers } from "shared/mock";
import toast from "react-hot-toast";

const ProcedurePerformedMovable: React.FC = () => {
  const {
    setStep,
    needsBand,
    setNeedsBand,
    needsSeparator,
    setNeedsSeparator,
    performMolding,
    setPerformMolding,
    installSeparator,
    setInstallSeparator,
    setMoldingJustification,
    setSeparatorJustification,
    patientObj,
    odontogramaSeparator,
    setOdontogramaSeparator,
    showQuestionsReturn,
    proceduresLastAttendance,
    currentProcedureIndexReturn,
    setJustificationReturn,
    setInformateRegionReturn,
    realizeReturn,
    setRealizeReturn,
    realizeContentReturn,
    setRealizeContentReturn,
    realizeAparatReturn,
    setRealizeAparatReturn,
    realizeEruptionReturn,
    setRealizeEruptionReturn,
    realizeIntermaxilaryReturn,
    setRealizeIntermaxilaryReturn,
    realizeCollageReturn,
    setRealizeCollageReturn,
    realizeRebondingReturn,
    setRealizeRebondingReturn,
    realizeBiteReturn,
    setRealizeBiteReturn,
    routeReturn,
    setRouteReturn,
    setJustifyRouteReturn,
    realizeReleaseReturn,
    setRealizeReleaseReturn,
    setJustificationContentReturn,
    setInformateRegionContentReturn,
    setJustificationAparatReturn,
    setJustificationEruptionReturn,
    setInformateRegionEruptionReturn,
    setJustificationIntermaxilaryReturn,
    setJustificationCollageReturn,
    setJustificationRebondingReturn,
    setJustificationBiteReturn,
    setJustificationReleaseReturn,
    setProceduresLastAttendance,
    odontogramaRemoteReturn,
    setOdontogramaRemoteReturn,
    odontogramaInstallReturn,
    setOdontogramaInstallReturn,
    odontogramaButtonReturn,
    setOdontogramaButtonReturn,
    odontogramaIntermaxReturn,
    setOdontogramaIntermaxReturn,
    odontogramaLevantReturn,
    setOdontogramaLevantReturn,
    braceTypeMaintenanceReturn,
    setBraceTypeMaintenanceReturn,
    repOrRecProcedureReturn,
    setRepOrRecProcedureReturn,
    repOrRecBraceProcedureReturn,
    setRepOrRecBraceProcedureReturn,
    repProcedureReturn,
    setRepProcedureReturn,
    painPrescriptionNeededMaintenanceReturn,
    setPainPrescriptionNeededMaintenanceReturn,
    informedBasicCareMaintenanceReturn,
    setInformedBasicCareMaintenanceReturn,
    setMaterialProcedureMaintenanceReturn,
    setNumberProcedureMaintenanceReturn,
    setOthersProceduresFixedMaintenanceReturn,
    basicCareObservationsMaintenanceReturn,
    setBasicCareObservationsMaintenanceReturn,
    repOrRecProcedure,
    repOrRecBraceProcedure,
    setCurrentProcedureIndexReturn,
    setShowQuestionsReturn,
    currentProcedureIndex,
    setCurrentProcedureIndex,
    hasOtherProcedures,
    setHasOtherProcedures,
    setOtherProceduresValue,
    realize,
    setRealize,
    setJustification,
    realizeContent,
    setRealizeContent,
    realizeAparat,
    setRealizeAparat,
    realizeEruption,
    setRealizeEruption,
    realizeIntermaxilary,
    setRealizeIntermaxilary,
    realizeCollage,
    setRealizeCollage,
    realizeRebonding,
    setRealizeRebonding,
    realizeBite,
    setRealizeBite,
    route,
    setRoute,
    setJustifyRoute,
    realizeRelease,
    setRealizeRelease,
    otherProceduresValue,
    setJustificationContent,
    setInformateRegionContent,
    setJustificationAparat,
    setJustificationEruption,
    setInformateRegionEruption,
    setJustificationIntermaxilary,
    setJustificationCollage,
    setJustificationRebonding,
    setJustificationBite,
    setJustificationRelease,
    setInformateRegion,
    odontogramaRemote,
    setOdontogramaRemote,
    odontogramaInstall,
    setOdontogramaInstall,
    odontogramaButton,
    setOdontogramaButton,
    odontogramaIntermax,
    setOdontogramaIntermax,
    odontogramaLevant,
    setOdontogramaLevant,
    braceTypeMaintenance,
    setBraceTypeMaintenance,
    setRepOrRecProcedure,
    setRepOrRecBraceProcedure,
    repProcedure,
    setRepProcedure,
    painPrescriptionNeededMaintenance,
    setPainPrescriptionNeededMaintenance,
    informedBasicCareMaintenance,
    setInformedBasicCareMaintenance,
    setMaterialProcedureMaintenance,
    setNumberProcedureMaintenance,
    setOthersProceduresFixedMaintenance,
    basicCareObservationsMaintenance,
    setBasicCareObservationsMaintenance,
    /*hasSeparator,
    moldageSecondMovable,
    setMoldageSecondMovable,
    installMovable,
    setInstallMovable,*/
    separatorJustification,
    moldingJustification,
    hasOtherReferrals,
    setHasOtherReferrals,
    setReferralValue,
    setInfMovable,
    setSupMovable,
    setBraceMovel,
    setRealizeMoldage,
    referralValue,
    /*supMovableProcedure,
    setSupMovableProcedure,
    infMovableProcedure,
    setInfMovableProcedure,*/
    questionsPlanning,

    justify15,
    setJustify15,
    justify16,
    setJustify16,

    installMovableReturn,
    setInstallMovableReturn,
    supMovableProcedureReturn,
    setSupMovableProcedureReturn,
    infMovableProcedureReturn,
    setInfMovableProcedureReturn,
    moldageSecondMovableReturn,
    setMoldageSecondMovableReturn,

    /*setOtherProceduresValueProcedures,
    otherProceduresValueProcedures,*/
    setValueReferralsProcedures,
    valueReferralsProcedures,
    setReceiveHigh,
    receiveHigh,

    verifyChange15,
    setVerifyChange15,
    verifyChange16,
    setVerifyChange16,
    setAllObservationsProcedure,
    allObservationsProcedure,

    realizeClear,
    setRealizeClear,
    setOtherProceduresValueSecondConsult,
    otherProceduresValueSecondConsult,
  } = useFirstConsult();

  const { proceduresData, referralsData } = useAuth();

  const handleNeedsBandClick = (value: "NO" | "YES") => {
    setNeedsBand(value);
    setNeedsSeparator("NOT");
    setPerformMolding("NOT");
    setInstallSeparator("NOT");
    setMoldingJustification("");
    setSeparatorJustification("");
    setInfMovable(false);
    setSupMovable(false);
    setBraceMovel(false);
    setRealizeMoldage(false);
  };

  const handleNeedsSeparatorClick = (value: "NO" | "YES") => {
    setNeedsSeparator(value);
    setPerformMolding("NOT");
    setInstallSeparator("NOT");
    setMoldingJustification("");
    setSeparatorJustification("");
    setInfMovable(false);
    setSupMovable(false);
    setBraceMovel(false);
    setRealizeMoldage(false);
  };

  const handlePerformMoldingClick = (value: "NO" | "YES") => {
    setPerformMolding(value);
    if (value === "YES") setMoldingJustification("");
  };

  const handleInstallSeparatorClick = (value: "NO" | "YES") => {
    setInstallSeparator(value);
    if (value === "YES") setSeparatorJustification("");
  };

  const shouldShowBtnFixed = () => {
    return performMolding !== "NOT" || installSeparator !== "NOT";
  };

  const [modalSeparator, setModalSeparator] = React.useState<boolean>(false);

  const next: any = () => {
    if (
      questionsPlanning.filter((item) => item.id === "PPQ015").length > 0 &&
      realizeClear === "NOT"
    ) {
      toast.error("Responda se realizou a limpeza");
      return false;
    }

    if (installSeparator === "NO" && separatorJustification.length < 1) {
      toast.error("Preencha a justificativa.");
      return false;
    }

    if (performMolding === "NO" && moldingJustification.length < 1) {
      toast.error("Preencha a justificativa.");
      return false;
    }

    if (verifyChange15 && justify15.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }

    if (verifyChange15 && justify15.length < 1) {
      toast.error("Preencha o campo justificativa");
      return false;
    }
    if (patientObj.proc_name === "Emergência") {
      setStep(6);
    }
    if (patientObj.future.scheduling === true) {
      setStep(6);
    } else {
      setStep(4);
    }
  };

  const [modalRemote, setModalRemote] = React.useState<boolean>(false);
  const [modalInstall, setModalInstall] = React.useState<boolean>(false);
  const [modalButton, setModalButton] = React.useState<boolean>(false);
  const [modalIntermax, setModalIntermax] = React.useState<boolean>(false);
  const [modalLevant, setModalLevant] = React.useState<boolean>(false);

  const [modalRemoteReturn, setModalRemoteReturn] =
    React.useState<boolean>(false);
  const [modalInstallReturn, setModalInstallReturn] =
    React.useState<boolean>(false);
  const [modalButtonReturn, setModalButtonReturn] =
    React.useState<boolean>(false);
  const [modalIntermaxReturn, setModalIntermaxReturn] =
    React.useState<boolean>(false);
  const [modalLevantReturn, setModalLevantReturn] =
    React.useState<boolean>(false);

  const [showOtherProcedureScreen, setShowOtherProcedureScreen] =
    React.useState(false);

  const descriptionsWithFlowZero = proceduresData
    .filter((procedure) => procedure.flow === 0)
    .map((procedure) => procedure.label);

  const newArrayValue = otherProceduresValue.filter(
    (procedure: any) => !descriptionsWithFlowZero.includes(procedure)
  );

  const continueReturn = () => {
    if (
      proceduresLastAttendance.length > 0 &&
      proceduresLastAttendance[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showQuestionsReturn) {
        if (currentProcedureIndexReturn < proceduresLastAttendance.length - 1) {
          setCurrentProcedureIndexReturn(currentProcedureIndexReturn + 1);
        } else {
          setShowQuestionsReturn(false);
        }
      } else {
        setCurrentProcedureIndexReturn(0);
        setShowQuestionsReturn(true);
      }
    } else {
      setShowQuestionsReturn(false);
    }
  };

  const handleContinue = () => {
    if (
      newArrayValue.length > 0 &&
      newArrayValue[0].length > 1 &&
      patientObj.proc_name !== "Emergência"
    ) {
      if (showOtherProcedureScreen) {
        if (currentProcedureIndex < newArrayValue.length - 1) {
          if (newArrayValue[currentProcedureIndex] === "Mini Implante") {
            if (realize === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] === "Controle de Contenção"
          ) {
            if (realizeContent === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Remoção de Aparelho") {
            if (realizeAparat === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Controle de Erupção") {
            if (realizeEruption === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (
            newArrayValue[currentProcedureIndex] ===
            "Instalacao de elasticos intermaxilares"
          ) {
            if (realizeIntermaxilary === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Colagem de Botão") {
            if (realizeCollage === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Recolagem Contenção") {
            if (realizeRebonding === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Levante de Mordida") {
            if (realizeBite === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (newArrayValue[currentProcedureIndex] === "Alta") {
            if (realizeRelease === "NOT") {
              toast.error("Responda a pergunta e tente novamente");
              return false;
            }
          }

          if (receiveHigh) {
            setStep(6);
          }

          setCurrentProcedureIndex(currentProcedureIndex + 1);
        } else {
          setStep(4);
        }
      } else {
        setCurrentProcedureIndex(0);
        setShowOtherProcedureScreen(true);
      }
    } else {
      setStep(4);
    }
  };

  const handleReturn = () => {
    if (currentProcedureIndex > 0) {
      setCurrentProcedureIndex(currentProcedureIndex - 1);
    } else {
      setShowOtherProcedureScreen(false);
    }
  };

  const handleReturnAttendance = () => {
    if (currentProcedureIndexReturn > 0) {
      setCurrentProcedureIndexReturn(currentProcedureIndexReturn - 1);
    } else {
      setShowQuestionsReturn(false);
    }
  };

  return (
    <>
      {showQuestionsReturn ? (
        <>
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {proceduresLastAttendance[currentProcedureIndexReturn]}
          </Typography>

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Mini Implante" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado o Mini Implante ortodôntico?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={realizeReturn === "NO" ? "contained" : "outlined"}
                  onClick={() => setRealizeReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={realizeReturn === "YES" ? "contained" : "outlined"}
                  onClick={() => setRealizeReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationReturn(e.target.value)}
                />
              )}

              {realizeReturn === "YES" && (
                <TextField
                  label="Informe a região onde foi realizado o procedimento"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setInformateRegionReturn(e.target.value)}
                />
              )}

              {realizeReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Controle de Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeContentReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeContentReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeContentReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeContentReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeContentReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationContentReturn(e.target.value)
                  }
                />
              )}
              {realizeContentReturn === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setInformateRegionContentReturn(e.target.value)
                  }
                />
              )}

              {realizeContentReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Remoção de Aparelho" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizada a Remoção da Aparatologia Ortodôntica?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeAparatReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeAparatReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeAparatReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeAparatReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeAparatReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationAparatReturn(e.target.value)}
                />
              )}

              {realizeAparatReturn === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalRemoteReturn(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalRemoteReturn}
                    onClose={() => setModalRemoteReturn(false)}
                  >
                    <Odontograma
                      onClose={() => setModalRemoteReturn(false)}
                      selected={odontogramaRemoteReturn}
                      setSelected={setOdontogramaRemoteReturn}
                      subtitle="Remoção de Aparatologia Ortodôntica - Planejamento da consulta anterior"
                    />
                  </Modal>
                </>
              )}

              {realizeAparatReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Controle de Erupção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi Realizado Controle de Erupção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeEruptionReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeEruptionReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeEruptionReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeEruptionReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeEruptionReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationEruptionReturn(e.target.value)
                  }
                />
              )}
              {realizeEruptionReturn === "YES" && (
                <TextField
                  label="Faça o registro do caso"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setInformateRegionEruptionReturn(e.target.value)
                  }
                />
              )}

              {realizeEruptionReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Instalação de elastico intermaxilares" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Foi Colocado Elástico?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeIntermaxilaryReturn === "NO"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilaryReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeIntermaxilaryReturn === "YES"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => setRealizeIntermaxilaryReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeIntermaxilaryReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationIntermaxilaryReturn(e.target.value)
                  }
                />
              )}

              {realizeIntermaxilaryReturn === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalInstallReturn(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalInstallReturn}
                    onClose={() => setModalInstallReturn(false)}
                  >
                    <Odontograma
                      onClose={() => setModalInstallReturn(false)}
                      selected={odontogramaInstallReturn}
                      setSelected={setOdontogramaInstallReturn}
                      subtitle="Instalação de elásticos intermaxilares - Planejamento da consulta anterior"
                    />
                  </Modal>
                </>
              )}

              {realizeIntermaxilaryReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Colagem de Botão" && (
            <Styled.BoxDescription>
              <Typography variant="h5">Colagem de botão?</Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeCollageReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeCollageReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeCollageReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeCollageReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeCollageReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationCollageReturn(e.target.value)
                  }
                />
              )}

              {realizeCollageReturn === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalButtonReturn(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalButtonReturn}
                    onClose={() => setModalButtonReturn(false)}
                  >
                    <Odontograma
                      onClose={() => setModalButtonReturn(false)}
                      selected={odontogramaButtonReturn}
                      setSelected={setOdontogramaButtonReturn}
                      subtitle="Colagem de Botão - Planejamento da consulta anterior"
                    />
                  </Modal>
                </>
              )}

              {realizeCollageReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Recolagem Contenção" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizada a recolagem de contenção?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeRebondingReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeRebondingReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeRebondingReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeRebondingReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeRebondingReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) =>
                    setJustificationRebondingReturn(e.target.value)
                  }
                />
              )}

              {realizeRebondingReturn === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalIntermaxReturn(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalIntermaxReturn}
                    onClose={() => setModalIntermaxReturn(false)}
                  >
                    <Odontograma
                      onClose={() => setModalIntermaxReturn(false)}
                      selected={odontogramaIntermaxReturn}
                      setSelected={setOdontogramaIntermaxReturn}
                      subtitle="Recolagem de Contenção - Planejamento da consulta anterior"
                    />
                  </Modal>
                </>
              )}

              {realizeRebondingReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Levante de Mordida" && (
            <Styled.BoxDescription>
              <Typography variant="h5">
                Foi realizado levante de mordida?
              </Typography>
              <Styled.BoxButtons>
                <Button
                  variant={
                    realizeBiteReturn === "NO" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeBiteReturn("NO")}
                >
                  NÃO
                </Button>
                <Button
                  variant={
                    realizeBiteReturn === "YES" ? "contained" : "outlined"
                  }
                  onClick={() => setRealizeBiteReturn("YES")}
                >
                  SIM
                </Button>
              </Styled.BoxButtons>
              {realizeBiteReturn === "NO" && (
                <TextField
                  label="Justificativa"
                  fullWidth
                  margin="normal"
                  onChange={(e) => setJustificationBiteReturn(e.target.value)}
                />
              )}

              {realizeBiteReturn === "YES" && (
                <>
                  <Button
                    sx={{ margin: "20px 0" }}
                    onClick={() => setModalLevantReturn(true)}
                  >
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalLevantReturn}
                    onClose={() => setModalLevantReturn(false)}
                  >
                    <Odontograma
                      onClose={() => setModalLevantReturn(false)}
                      selected={odontogramaLevantReturn}
                      setSelected={setOdontogramaLevantReturn}
                      subtitle="Levante de Mordida - Planejamento da consulta anterior"
                    />
                  </Modal>
                </>
              )}

              {realizeBiteReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </Styled.BoxDescription>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] === "Alta" && (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Paciente recebeu alta?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={
                      realizeReleaseReturn === "NO" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeReleaseReturn("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={
                      realizeReleaseReturn === "YES" ? "contained" : "outlined"
                    }
                    onClick={() => setRealizeReleaseReturn("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
                {realizeReleaseReturn === "NO" && (
                  <TextField
                    label="Justificativa"
                    fullWidth
                    margin="normal"
                    onChange={(e) =>
                      setJustificationReleaseReturn(e.target.value)
                    }
                  />
                )}
              </Styled.BoxDescription>

              {realizeReleaseReturn === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Paciente foi encaminhado para a documentação final?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={routeReturn === "NO" ? "contained" : "outlined"}
                      onClick={() => setRouteReturn("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={routeReturn === "YES" ? "contained" : "outlined"}
                      onClick={() => setRouteReturn("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {routeReturn === "NO" && (
                    <TextField
                      label="Justificativa"
                      fullWidth
                      margin="normal"
                      onChange={(e) => setJustifyRouteReturn(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {realizeReleaseReturn !== "NOT" && (
                <>
                  <Autocomplete
                    options={proceduresData.map((option) => option.label)}
                    multiple
                    sx={{ margin: "10px 0" }}
                    onChange={(event, newValue) => {
                      const updatedProcedures = [...proceduresLastAttendance];

                      newValue.forEach((value) => {
                        if (!updatedProcedures.includes(value)) {
                          updatedProcedures.push(value);
                        }
                      });

                      setProceduresLastAttendance(updatedProcedures);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Outros procedimentos" />
                    )}
                  />
                </>
              )}
            </>
          )}

          {proceduresLastAttendance[currentProcedureIndexReturn] ===
            "Manutenção" && (
            <>
              <Styled.BoxIndication>
                <Styled.BoxButtons
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  <Button
                    variant={
                      braceTypeMaintenanceReturn === "UPPER"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setBraceTypeMaintenanceReturn("UPPER");
                    }}
                  >
                    Trocar fio
                  </Button>
                  <Button
                    variant={
                      braceTypeMaintenanceReturn === "LOWER"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setBraceTypeMaintenanceReturn("LOWER");
                    }}
                  >
                    Manter fio
                  </Button>
                </Styled.BoxButtons>
                {braceTypeMaintenanceReturn !== "NOT" && (
                  <Box display="flex" alignItems="center" mt={2} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="material-select-label"
                        sx={{ backgroundColor: "white", padding: "2px" }}
                      >
                        Material
                      </InputLabel>
                      <Select
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "90%" }}
                        style={{ marginRight: 16 }}
                        onChange={(e) =>
                          setMaterialProcedureMaintenanceReturn(
                            e.target.value as string
                          )
                        }
                      >
                        {materialOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel
                        id="number-select-label"
                        sx={{ backgroundColor: "white", padding: "2px" }}
                      >
                        N°
                      </InputLabel>
                      <Select
                        displayEmpty
                        variant="outlined"
                        sx={{ width: "90%" }}
                        style={{ marginRight: 16 }}
                        onChange={(e) =>
                          setNumberProcedureMaintenanceReturn(
                            e.target.value as string
                          )
                        }
                      >
                        {numbers.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Outros"
                      onChange={(e) =>
                        setOthersProceduresFixedMaintenanceReturn(
                          e.target.value as string
                        )
                      }
                    ></TextField>
                  </Box>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={repProcedureReturn}
                        onClick={() => {
                          setRepProcedureReturn(!repProcedureReturn);
                        }}
                      />
                    }
                    label="Reposição ou Recolagem de Braquete"
                  />
                </FormGroup>
                {repProcedureReturn && (
                  <>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          repOrRecProcedure === "REP" ? "contained" : "outlined"
                        }
                        onClick={() => setRepOrRecProcedureReturn("REP")}
                      >
                        Reposição
                      </Button>
                      <Button
                        variant={
                          repOrRecProcedure === "REC" ? "contained" : "outlined"
                        }
                        onClick={() => setRepOrRecProcedureReturn("REC")}
                      >
                        Recolagem
                      </Button>
                    </Styled.BoxButtons>

                    {repOrRecProcedureReturn !== "NOT" && (
                      <Styled.BoxButtons
                        style={{ marginTop: "20px", paddingBottom: "10px" }}
                      >
                        <Button
                          variant={
                            repOrRecBraceProcedure === "SUP"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecBraceProcedureReturn("SUP")}
                        >
                          Superior
                        </Button>
                        <Button
                          variant={
                            repOrRecBraceProcedureReturn === "INF"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRepOrRecBraceProcedureReturn("INF")}
                        >
                          Inferior
                        </Button>
                      </Styled.BoxButtons>
                    )}
                  </>
                )}
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Precisa de receita para o caso de dor?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      painPrescriptionNeededMaintenanceReturn === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeededMaintenanceReturn("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      painPrescriptionNeededMaintenanceReturn === "NO"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setPainPrescriptionNeededMaintenanceReturn("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxIndication>

              <Styled.BoxIndication>
                <Typography
                  variant="h5"
                  sx={{ marginTop: "5px", marginBottom: "10px" }}
                >
                  Informou o paciente sobre os cuidados básicos com o aparelho?
                </Typography>
                <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                  <Button
                    variant={
                      informedBasicCareMaintenanceReturn === "YES"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCareMaintenanceReturn("YES");
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    variant={
                      informedBasicCareMaintenanceReturn === "NO"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => {
                      setInformedBasicCareMaintenanceReturn("NO");
                    }}
                  >
                    Não
                  </Button>
                </Styled.BoxButtons>
                {informedBasicCareMaintenanceReturn === "NO" && (
                  <TextField
                    fullWidth
                    label="Justifique"
                    variant="outlined"
                    sx={{ marginTop: "10px", paddingBottom: "5px" }}
                    value={basicCareObservationsMaintenanceReturn}
                    onChange={(e) => {
                      setBasicCareObservationsMaintenanceReturn(e.target.value);
                    }}
                  />
                )}
              </Styled.BoxIndication>

              <>
                <Autocomplete
                  options={proceduresData.map((option) => option.label)}
                  multiple
                  sx={{ margin: "10px 0" }}
                  onChange={(event, newValue) => {
                    const updatedProcedures = [...proceduresLastAttendance];

                    newValue.forEach((value) => {
                      if (!updatedProcedures.includes(value)) {
                        updatedProcedures.push(value);
                      }
                    });

                    setProceduresLastAttendance(updatedProcedures);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Outros procedimentos" />
                  )}
                />
              </>
            </>
          )}

          {currentProcedureIndexReturn === 0 ? (
            <Styled.BtnFixedAnam>
              <Button onClick={continueReturn} fullWidth>
                {currentProcedureIndexReturn <
                proceduresLastAttendance.length - 1
                  ? "Próximo"
                  : "Continuar"}
              </Button>
            </Styled.BtnFixedAnam>
          ) : (
            <Styled.BtnFixed>
              <Button onClick={handleReturnAttendance} fullWidth>
                {currentProcedureIndexReturn === 0 ? "Retornar" : "Anterior"}
              </Button>
              <Button onClick={continueReturn} fullWidth>
                {currentProcedureIndexReturn <
                proceduresLastAttendance.length - 1
                  ? "Próximo"
                  : "Continuar"}
              </Button>
            </Styled.BtnFixed>
          )}
        </>
      ) : (
        <Styled.Box>
          {patientObj.aname_id > 0 ? (
            <>
              {showOtherProcedureScreen ? (
                <>
                  <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                    {newArrayValue[currentProcedureIndex]}
                  </Typography>

                  {newArrayValue[currentProcedureIndex] === "Mini Implante" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi Realizado o Mini Implante ortodôntico?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={realize === "NO" ? "contained" : "outlined"}
                          onClick={() => setRealize("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={realize === "YES" ? "contained" : "outlined"}
                          onClick={() => setRealize("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realize === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) => setJustification(e.target.value)}
                        />
                      )}

                      {realize === "YES" && (
                        <TextField
                          label="Informe a região onde foi realizado o procedimento"
                          fullWidth
                          margin="normal"
                          onChange={(e) => setInformateRegion(e.target.value)}
                        />
                      )}
                      {realize !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Controle de Contenção" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi Realizado Controle de Contenção?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeContent === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeContent("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeContent === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeContent("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeContent === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationContent(e.target.value)
                          }
                        />
                      )}
                      {realizeContent === "YES" && (
                        <TextField
                          label="Faça o registro do caso"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setInformateRegionContent(e.target.value)
                          }
                        />
                      )}

                      {realizeContent !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />
                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Remoção de Aparelho" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi Realizada a Remoção da Aparatologia Ortodôntica?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeAparat === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeAparat("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeAparat === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeAparat("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeAparat === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationAparat(e.target.value)
                          }
                        />
                      )}

                      {realizeAparat === "YES" && (
                        <>
                          <Button
                            sx={{ margin: "20px 0" }}
                            onClick={() => setModalRemote(true)}
                          >
                            Abrir Odontograma
                          </Button>

                          <Modal
                            open={modalRemote}
                            onClose={() => setModalRemote(false)}
                          >
                            <Odontograma
                              onClose={() => setModalRemote(false)}
                              selected={odontogramaRemote}
                              setSelected={setOdontogramaRemote}
                              subtitle="Remoção de Aparatologia Ortodôntica"
                            />
                          </Modal>
                        </>
                      )}

                      {realizeAparat !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />
                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Controle de Erupção" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi Realizado Controle de Erupção?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeEruption === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeEruption("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeEruption === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeEruption("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeEruption === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationEruption(e.target.value)
                          }
                        />
                      )}
                      {realizeEruption === "YES" && (
                        <TextField
                          label="Faça o registro do caso"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setInformateRegionEruption(e.target.value)
                          }
                        />
                      )}

                      {realizeEruption !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />
                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Instalacao de elasticos intermaxilares" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi Colocado Elástico?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeIntermaxilary === "NO"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRealizeIntermaxilary("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeIntermaxilary === "YES"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRealizeIntermaxilary("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeIntermaxilary === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationIntermaxilary(e.target.value)
                          }
                        />
                      )}

                      {realizeIntermaxilary === "YES" && (
                        <>
                          <Button
                            sx={{ margin: "20px 0" }}
                            onClick={() => setModalInstall(true)}
                          >
                            Abrir Odontograma
                          </Button>

                          <Modal
                            open={modalInstall}
                            onClose={() => setModalInstall(false)}
                          >
                            <Odontograma
                              onClose={() => setModalInstall(false)}
                              selected={odontogramaInstall}
                              setSelected={setOdontogramaInstall}
                              subtitle="Instalação de elásticos intermaxilares"
                            />
                          </Modal>
                        </>
                      )}

                      {realizeIntermaxilary !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Colagem de Botão" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">Colagem de botão?</Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeCollage === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeCollage("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeCollage === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeCollage("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeCollage === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationCollage(e.target.value)
                          }
                        />
                      )}

                      {realizeCollage === "YES" && (
                        <>
                          <Button
                            sx={{ margin: "20px 0" }}
                            onClick={() => setModalButton(true)}
                          >
                            Abrir Odontograma
                          </Button>

                          <Modal
                            open={modalButton}
                            onClose={() => setModalButton(false)}
                          >
                            <Odontograma
                              onClose={() => setModalButton(false)}
                              selected={odontogramaButton}
                              setSelected={setOdontogramaButton}
                              subtitle="Colagem de Botão"
                            />
                          </Modal>
                        </>
                      )}

                      {realizeCollage !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Recolagem Contenção" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi realizada a recolagem de contenção?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeRebonding === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeRebonding("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeRebonding === "YES"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => setRealizeRebonding("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeRebonding === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) =>
                            setJustificationRebonding(e.target.value)
                          }
                        />
                      )}

                      {realizeRebonding === "YES" && (
                        <>
                          <Button
                            sx={{ margin: "20px 0" }}
                            onClick={() => setModalIntermax(true)}
                          >
                            Abrir Odontograma
                          </Button>

                          <Modal
                            open={modalIntermax}
                            onClose={() => setModalIntermax(false)}
                          >
                            <Odontograma
                              onClose={() => setModalIntermax(false)}
                              selected={odontogramaIntermax}
                              setSelected={setOdontogramaIntermax}
                              subtitle="Recolagem de Contenção"
                            />
                          </Modal>
                        </>
                      )}

                      {realizeRebonding !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] ===
                    "Levante de Mordida" && (
                    <Styled.BoxDescription>
                      <Typography variant="h5">
                        Foi realizado levante de mordida?
                      </Typography>
                      <Styled.BoxButtons>
                        <Button
                          variant={
                            realizeBite === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeBite("NO")}
                        >
                          NÃO
                        </Button>
                        <Button
                          variant={
                            realizeBite === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => setRealizeBite("YES")}
                        >
                          SIM
                        </Button>
                      </Styled.BoxButtons>
                      {realizeBite === "NO" && (
                        <TextField
                          label="Justificativa"
                          fullWidth
                          margin="normal"
                          onChange={(e) => setJustificationBite(e.target.value)}
                        />
                      )}

                      {realizeBite === "YES" && (
                        <>
                          <Button
                            sx={{ margin: "20px 0" }}
                            onClick={() => setModalLevant(true)}
                          >
                            Abrir Odontograma
                          </Button>

                          <Modal
                            open={modalLevant}
                            onClose={() => setModalLevant(false)}
                          >
                            <Odontograma
                              onClose={() => setModalLevant(false)}
                              selected={odontogramaLevant}
                              setSelected={setOdontogramaLevant}
                              subtitle="Levante de Mordida"
                            />
                          </Modal>
                        </>
                      )}

                      {realizeBite !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </Styled.BoxDescription>
                  )}

                  {newArrayValue[currentProcedureIndex] === "Alta" && (
                    <>
                      <Styled.BoxDescription>
                        <Typography variant="h5">
                          Paciente recebeu alta?
                        </Typography>
                        <Styled.BoxButtons>
                          <Button
                            variant={
                              realizeRelease === "NO" ? "contained" : "outlined"
                            }
                            onClick={() => {
                              setRealizeRelease("NO");
                              setReceiveHigh(false);
                            }}
                          >
                            NÃO
                          </Button>
                          <Button
                            variant={
                              realizeRelease === "YES"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setRealizeRelease("YES");
                              setReceiveHigh(true);
                            }}
                          >
                            SIM
                          </Button>
                        </Styled.BoxButtons>
                        {realizeRelease === "NO" && (
                          <TextField
                            label="Justificativa"
                            fullWidth
                            margin="normal"
                            onChange={(e) =>
                              setJustificationRelease(e.target.value)
                            }
                          />
                        )}
                      </Styled.BoxDescription>

                      {realizeRelease === "YES" && (
                        <Styled.BoxDescription>
                          <Typography variant="h5">
                            Paciente foi encaminhado para a documentação final?
                          </Typography>
                          <Styled.BoxButtons>
                            <Button
                              variant={
                                route === "NO" ? "contained" : "outlined"
                              }
                              onClick={() => setRoute("NO")}
                            >
                              NÃO
                            </Button>
                            <Button
                              variant={
                                route === "YES" ? "contained" : "outlined"
                              }
                              onClick={() => setRoute("YES")}
                            >
                              SIM
                            </Button>
                          </Styled.BoxButtons>
                          {route === "NO" && (
                            <TextField
                              label="Justificativa"
                              fullWidth
                              margin="normal"
                              onChange={(e) => setJustifyRoute(e.target.value)}
                            />
                          )}
                        </Styled.BoxDescription>
                      )}

                      {realizeRelease !== "NOT" && (
                        <>
                          <Autocomplete
                            options={proceduresData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) => !otherProceduresValue.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setOtherProceduresValue((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Outros procedimentos"
                              />
                            )}
                          />

                          <Autocomplete
                            options={referralsData}
                            multiple
                            sx={{ margin: "10px 0" }}
                            onChange={(event, newValue) => {
                              const uniqueValues = newValue.filter(
                                (value) =>
                                  !valueReferralsProcedures.includes(value)
                              );

                              // Atualiza o estado com os valores únicos
                              setValueReferralsProcedures((prevValue: any) => [
                                ...prevValue,
                                ...uniqueValues,
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Encaminhamentos" />
                            )}
                          />
                        </>
                      )}
                    </>
                  )}

                  {newArrayValue[currentProcedureIndex] === "Manutenção" && (
                    <>
                      <Styled.BoxIndication>
                        <Styled.BoxButtons
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          <Button
                            variant={
                              braceTypeMaintenance === "UPPER"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setBraceTypeMaintenance("UPPER");
                            }}
                          >
                            Trocar fio
                          </Button>
                          <Button
                            variant={
                              braceTypeMaintenance === "LOWER"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setBraceTypeMaintenance("LOWER");
                            }}
                          >
                            Manter fio
                          </Button>
                        </Styled.BoxButtons>
                        {braceTypeMaintenance !== "NOT" && (
                          <Box display="flex" alignItems="center" mt={2} mb={2}>
                            <FormControl fullWidth>
                              <InputLabel
                                id="material-select-label"
                                sx={{
                                  backgroundColor: "white",
                                  padding: "2px",
                                }}
                              >
                                Material
                              </InputLabel>
                              <Select
                                displayEmpty
                                variant="outlined"
                                sx={{ width: "90%" }}
                                style={{ marginRight: 16 }}
                                onChange={(e) =>
                                  setMaterialProcedureMaintenance(
                                    e.target.value as string
                                  )
                                }
                              >
                                {materialOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel
                                id="number-select-label"
                                sx={{
                                  backgroundColor: "white",
                                  padding: "2px",
                                }}
                              >
                                N°
                              </InputLabel>
                              <Select
                                displayEmpty
                                variant="outlined"
                                sx={{ width: "90%" }}
                                style={{ marginRight: 16 }}
                                onChange={(e) =>
                                  setNumberProcedureMaintenance(
                                    e.target.value as string
                                  )
                                }
                              >
                                {numbers.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              label="Outros"
                              onChange={(e) =>
                                setOthersProceduresFixedMaintenance(
                                  e.target.value as string
                                )
                              }
                            ></TextField>
                          </Box>
                        )}
                      </Styled.BoxIndication>

                      <Styled.BoxIndication>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={repProcedure}
                                onClick={() => {
                                  setRepProcedure(!repProcedure);
                                }}
                              />
                            }
                            label="Reposição ou Recolagem de Braquete"
                          />
                        </FormGroup>
                        {repProcedure && (
                          <>
                            <Styled.BoxButtons>
                              <Button
                                variant={
                                  repOrRecProcedure === "REP"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => setRepOrRecProcedure("REP")}
                              >
                                Reposição
                              </Button>
                              <Button
                                variant={
                                  repOrRecProcedure === "REC"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => setRepOrRecProcedure("REC")}
                              >
                                Recolagem
                              </Button>
                            </Styled.BoxButtons>

                            {repOrRecProcedure !== "NOT" && (
                              <Styled.BoxButtons
                                style={{
                                  marginTop: "20px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <Button
                                  variant={
                                    repOrRecBraceProcedure === "SUP"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() =>
                                    setRepOrRecBraceProcedure("SUP")
                                  }
                                >
                                  Superior
                                </Button>
                                <Button
                                  variant={
                                    repOrRecBraceProcedure === "INF"
                                      ? "contained"
                                      : "outlined"
                                  }
                                  onClick={() =>
                                    setRepOrRecBraceProcedure("INF")
                                  }
                                >
                                  Inferior
                                </Button>
                              </Styled.BoxButtons>
                            )}
                          </>
                        )}
                      </Styled.BoxIndication>

                      <Styled.BoxIndication>
                        <Typography
                          variant="h5"
                          sx={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                          Precisa de receita para o caso de dor?
                        </Typography>
                        <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                          <Button
                            variant={
                              painPrescriptionNeededMaintenance === "YES"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setPainPrescriptionNeededMaintenance("YES");
                            }}
                          >
                            Sim
                          </Button>
                          <Button
                            variant={
                              painPrescriptionNeededMaintenance === "NO"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setPainPrescriptionNeededMaintenance("NO");
                            }}
                          >
                            Não
                          </Button>
                        </Styled.BoxButtons>
                      </Styled.BoxIndication>

                      <Styled.BoxIndication>
                        <Typography
                          variant="h5"
                          sx={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                          Informou o paciente sobre os cuidados básicos com o
                          aparelho?
                        </Typography>
                        <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                          <Button
                            variant={
                              informedBasicCareMaintenance === "YES"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setInformedBasicCareMaintenance("YES");
                            }}
                          >
                            Sim
                          </Button>
                          <Button
                            variant={
                              informedBasicCareMaintenance === "NO"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              setInformedBasicCareMaintenance("NO");
                            }}
                          >
                            Não
                          </Button>
                        </Styled.BoxButtons>
                        {informedBasicCareMaintenance === "NO" && (
                          <TextField
                            fullWidth
                            label="Justifique"
                            variant="outlined"
                            sx={{ marginTop: "10px", paddingBottom: "5px" }}
                            value={basicCareObservationsMaintenance}
                            onChange={(e) => {
                              setBasicCareObservationsMaintenance(
                                e.target.value
                              );
                            }}
                          />
                        )}

                        {braceTypeMaintenance !== "NOT" && (
                          <>
                            <Autocomplete
                              options={proceduresData}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                const uniqueValues = newValue.filter(
                                  (value) =>
                                    !otherProceduresValue.includes(value)
                                );

                                // Atualiza o estado com os valores únicos
                                setOtherProceduresValue((prevValue: any) => [
                                  ...prevValue,
                                  ...uniqueValues,
                                ]);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Outros procedimentos"
                                />
                              )}
                            />

                            <Autocomplete
                              options={referralsData}
                              multiple
                              sx={{ margin: "10px 0" }}
                              onChange={(event, newValue) => {
                                const uniqueValues = newValue.filter(
                                  (value) =>
                                    !valueReferralsProcedures.includes(value)
                                );

                                // Atualiza o estado com os valores únicos
                                setValueReferralsProcedures(
                                  (prevValue: any) => [
                                    ...prevValue,
                                    ...uniqueValues,
                                  ]
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Encaminhamentos"
                                />
                              )}
                            />
                          </>
                        )}
                      </Styled.BoxIndication>
                    </>
                  )}

                  <Styled.BtnFixed>
                    <Button onClick={handleReturn} fullWidth>
                      {currentProcedureIndex === 0 ? "Retornar" : "Anterior"}
                    </Button>
                    <Button onClick={handleContinue} fullWidth>
                      {currentProcedureIndex < newArrayValue.length - 1
                        ? "Próximo"
                        : "Continuar"}
                    </Button>
                  </Styled.BtnFixed>
                </>
              ) : (
                <>
                  {questionsPlanning.length > 0 ? (
                    <>
                      {patientObj.proc_name !== "Emergência" && (
                        <Styled.BoxPlanning>
                          <Typography variant="h5">
                            Planejamento da consulta anterior
                          </Typography>

                          {questionsPlanning.filter(
                            (item) => item.id === "PPQ015"
                          ).length > 0 ? (
                            <Styled.BoxIndication>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={installMovableReturn}
                                      onClick={() => {
                                        setInstallMovableReturn(
                                          !installMovableReturn
                                        );
                                        setVerifyChange15(true);
                                      }}
                                    />
                                  }
                                  label="Instalação do Aparelho Móvel"
                                />
                              </FormGroup>
                              {installMovableReturn && (
                                <Styled.BoxButtons>
                                  <Button
                                    variant={
                                      supMovableProcedureReturn
                                        ? "contained"
                                        : "outlined"
                                    }
                                    onClick={() => {
                                      setSupMovableProcedureReturn(
                                        !supMovableProcedureReturn
                                      );
                                      setVerifyChange15(true);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor:
                                          supMovableProcedureReturn
                                            ? "#007DED"
                                            : "white",
                                        color: supMovableProcedureReturn
                                          ? "white"
                                          : "#007DED",
                                        borderColor: supMovableProcedureReturn
                                          ? "#007DED"
                                          : "#EEEFF1",
                                      },
                                    }}
                                  >
                                    Superior
                                  </Button>
                                  <Button
                                    variant={
                                      infMovableProcedureReturn
                                        ? "contained"
                                        : "outlined"
                                    }
                                    onClick={() => {
                                      setInfMovableProcedureReturn(
                                        !infMovableProcedureReturn
                                      );
                                      setVerifyChange15(true);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor:
                                          infMovableProcedureReturn
                                            ? "#007DED"
                                            : "white",
                                        color: infMovableProcedureReturn
                                          ? "white"
                                          : "#007DED",
                                        borderColor: infMovableProcedureReturn
                                          ? "#007DED"
                                          : "#EEEFF1",
                                      },
                                    }}
                                  >
                                    Inferior
                                  </Button>
                                </Styled.BoxButtons>
                              )}

                              {verifyChange15 && (
                                <TextField
                                  label="Justifique a mudança"
                                  onChange={(e) => setJustify15(e.target.value)}
                                  defaultValue={justify15}
                                  sx={{ marginTop: "20px" }}
                                  error={verifyChange15 && justify15.length < 1}
                                  helperText={
                                    verifyChange15 && justify15.length < 1
                                      ? "Justifique sua alteração"
                                      : ""
                                  }
                                />
                              )}
                            </Styled.BoxIndication>
                          ) : (
                            ""
                          )}

                          {questionsPlanning.filter(
                            (item) => item.id === "PPQ016"
                          ).length > 0 ? (
                            <Styled.BoxIndication>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={moldageSecondMovableReturn}
                                      onClick={() => {
                                        setMoldageSecondMovableReturn(
                                          !moldageSecondMovableReturn
                                        );
                                        setVerifyChange16(true);
                                      }}
                                    />
                                  }
                                  label="Realizar a moldagem"
                                />
                              </FormGroup>
                              {moldageSecondMovableReturn && (
                                <Styled.BoxButtons>
                                  <Button
                                    variant={
                                      supMovableProcedureReturn
                                        ? "contained"
                                        : "outlined"
                                    }
                                    onClick={() => {
                                      setSupMovableProcedureReturn(
                                        !supMovableProcedureReturn
                                      );
                                      setVerifyChange16(true);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor:
                                          supMovableProcedureReturn
                                            ? "#007DED"
                                            : "white",
                                        color: supMovableProcedureReturn
                                          ? "white"
                                          : "#007DED",
                                        borderColor: supMovableProcedureReturn
                                          ? "#007DED"
                                          : "#EEEFF1",
                                      },
                                    }}
                                  >
                                    Superior
                                  </Button>
                                  <Button
                                    variant={
                                      infMovableProcedureReturn
                                        ? "contained"
                                        : "outlined"
                                    }
                                    onClick={() => {
                                      setInfMovableProcedureReturn(
                                        !infMovableProcedureReturn
                                      );
                                      setVerifyChange16(true);
                                    }}
                                    sx={{
                                      "&:hover": {
                                        backgroundColor:
                                          infMovableProcedureReturn
                                            ? "#007DED"
                                            : "white",
                                        color: infMovableProcedureReturn
                                          ? "white"
                                          : "#007DED",
                                        borderColor: infMovableProcedureReturn
                                          ? "#007DED"
                                          : "#EEEFF1",
                                      },
                                    }}
                                  >
                                    Inferior
                                  </Button>
                                </Styled.BoxButtons>
                              )}

                              {verifyChange16 && (
                                <TextField
                                  label="Justifique a mudança"
                                  onChange={(e) => setJustify16(e.target.value)}
                                  sx={{ marginTop: "20px" }}
                                  error={verifyChange16 && justify16.length < 1}
                                  defaultValue={justify16}
                                  helperText={
                                    verifyChange16 && justify16.length < 1
                                      ? "Justifique sua alteração"
                                      : ""
                                  }
                                />
                              )}
                            </Styled.BoxIndication>
                          ) : (
                            ""
                          )}
                        </Styled.BoxPlanning>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {/*hasSeparator ? (
                    <Styled.BoxIndication>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={moldageSecondMovable}
                              onClick={() =>
                                setMoldageSecondMovable(!moldageSecondMovable)
                              }
                            />
                          }
                          label="Realizar a moldagem"
                        />
                      </FormGroup>
                      {moldageSecondMovable && (
                        <Styled.BoxButtons>
                          <Button
                            variant={
                              supMovableProcedure ? "contained" : "outlined"
                            }
                            onClick={() =>
                              setSupMovableProcedure(!supMovableProcedure)
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: supMovableProcedure
                                  ? "#007DED"
                                  : "white",
                                color: supMovableProcedure
                                  ? "white"
                                  : "#007DED",
                                borderColor: supMovableProcedure
                                  ? "#007DED"
                                  : "#EEEFF1",
                              },
                            }}
                          >
                            Superior
                          </Button>
                          <Button
                            variant={
                              infMovableProcedure ? "contained" : "outlined"
                            }
                            onClick={() =>
                              setInfMovableProcedure(!infMovableProcedure)
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: infMovableProcedure
                                  ? "#007DED"
                                  : "white",
                                color: infMovableProcedure
                                  ? "white"
                                  : "#007DED",
                                borderColor: infMovableProcedure
                                  ? "#007DED"
                                  : "#EEEFF1",
                              },
                            }}
                          >
                            Inferior
                          </Button>
                        </Styled.BoxButtons>
                      )}
                    </Styled.BoxIndication>
                  ) : (
                    <Styled.BoxIndication>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={installMovable}
                              onClick={() => setInstallMovable(!installMovable)}
                            />
                          }
                          label="Instalação do Aparelho Móvel"
                        />
                      </FormGroup>
                      {installMovable && (
                        <Styled.BoxButtons>
                          <Button
                            variant={
                              supMovableProcedure ? "contained" : "outlined"
                            }
                            onClick={() =>
                              setSupMovableProcedure(!supMovableProcedure)
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: supMovableProcedure
                                  ? "#007DED"
                                  : "white",
                                color: supMovableProcedure
                                  ? "white"
                                  : "#007DED",
                                borderColor: supMovableProcedure
                                  ? "#007DED"
                                  : "#EEEFF1",
                              },
                            }}
                          >
                            Superior
                          </Button>
                          <Button
                            variant={
                              infMovableProcedure ? "contained" : "outlined"
                            }
                            onClick={() =>
                              setInfMovableProcedure(!infMovableProcedure)
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: infMovableProcedure
                                  ? "#007DED"
                                  : "white",
                                color: infMovableProcedure
                                  ? "white"
                                  : "#007DED",
                                borderColor: infMovableProcedure
                                  ? "#007DED"
                                  : "#EEEFF1",
                              },
                            }}
                          >
                            Inferior
                          </Button>
                        </Styled.BoxButtons>
                      )}
                    </Styled.BoxIndication>
                  )*/}

                  {questionsPlanning.filter((item) => item.id === "PPQ015")
                    .length > 0 ? (
                    <Styled.BoxIndication>
                      <Typography
                        variant="h5"
                        sx={{ marginTop: "5px", marginBottom: "10px" }}
                      >
                        Foi realizada a limpeza?
                      </Typography>
                      <Styled.BoxButtons style={{ marginBottom: "10px" }}>
                        <Button
                          variant={
                            realizeClear === "YES" ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setRealizeClear("YES");
                          }}
                        >
                          Sim
                        </Button>
                        <Button
                          variant={
                            realizeClear === "NO" ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setRealizeClear("NO");
                          }}
                        >
                          Não
                        </Button>
                      </Styled.BoxButtons>
                    </Styled.BoxIndication>
                  ) : (
                    ""
                  )}

                  <Styled.BoxIndication>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasOtherProcedures}
                            onClick={() =>
                              setHasOtherProcedures(!hasOtherProcedures)
                            }
                          />
                        }
                        label="Outros procedimentos"
                      />
                    </FormGroup>
                    {hasOtherProcedures && (
                      <>
                        <Autocomplete
                          options={proceduresData.map((option) => option.label)}
                          multiple
                          sx={{ margin: "10px 0" }}
                          onChange={(event, newValue) =>
                            setOtherProceduresValueSecondConsult(newValue)
                          }
                          defaultValue={otherProceduresValueSecondConsult}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              defaultValue={otherProceduresValueSecondConsult}
                              label="Outros procedimentos"
                            />
                          )}
                        />
                      </>
                    )}
                  </Styled.BoxIndication>

                  <Styled.BoxIndication>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasOtherReferrals}
                            onClick={() =>
                              setHasOtherReferrals(!hasOtherReferrals)
                            }
                          />
                        }
                        label="Encaminhamentos"
                      />
                    </FormGroup>
                    {hasOtherReferrals && (
                      <>
                        <Autocomplete
                          options={referralsData}
                          multiple
                          sx={{ margin: "10px 0" }}
                          onChange={(event, newValue) =>
                            setReferralValue(newValue)
                          }
                          defaultValue={referralValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              defaultValue={referralValue}
                              label="Encaminhamentos"
                            />
                          )}
                        />
                      </>
                    )}
                  </Styled.BoxIndication>

                  <TextField
                    label="Observações gerais"
                    defaultValue={allObservationsProcedure}
                    onChange={(e) =>
                      setAllObservationsProcedure(e.target.value)
                    }
                  />

                  {patientObj.aname_id > 0 ? (
                    <Styled.BtnFixedAnam>
                      <Button fullWidth onClick={handleContinue}>
                        Continuar
                      </Button>
                    </Styled.BtnFixedAnam>
                  ) : (
                    shouldShowBtnFixed() && (
                      <Styled.BtnFixed>
                        <Button
                          fullWidth
                          onClick={() => setStep(2)}
                          variant="outlined"
                        >
                          Voltar
                        </Button>

                        <Button fullWidth onClick={next}>
                          Continuar
                        </Button>
                      </Styled.BtnFixed>
                    )
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Styled.BoxDescription>
                <Typography variant="h5">Vai precisar de banda?</Typography>
                <Styled.BoxButtons>
                  <Button
                    variant={needsBand === "NO" ? "contained" : "outlined"}
                    onClick={() => handleNeedsBandClick("NO")}
                  >
                    NÃO
                  </Button>
                  <Button
                    variant={needsBand === "YES" ? "contained" : "outlined"}
                    onClick={() => handleNeedsBandClick("YES")}
                  >
                    SIM
                  </Button>
                </Styled.BoxButtons>
              </Styled.BoxDescription>

              {needsBand === "NO" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">Realizou a moldagem?</Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        performMolding === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handlePerformMoldingClick("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        performMolding === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handlePerformMoldingClick("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                  {performMolding === "NO" && (
                    <TextField
                      label="Justifique"
                      onChange={(e) => setMoldingJustification(e.target.value)}
                    />
                  )}
                </Styled.BoxDescription>
              )}

              {needsBand === "YES" && (
                <Styled.BoxDescription>
                  <Typography variant="h5">
                    Vai precisar de separador?
                  </Typography>
                  <Styled.BoxButtons>
                    <Button
                      variant={
                        needsSeparator === "NO" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClick("NO")}
                    >
                      NÃO
                    </Button>
                    <Button
                      variant={
                        needsSeparator === "YES" ? "contained" : "outlined"
                      }
                      onClick={() => handleNeedsSeparatorClick("YES")}
                    >
                      SIM
                    </Button>
                  </Styled.BoxButtons>
                </Styled.BoxDescription>
              )}

              {needsSeparator === "NO" && needsBand === "YES" && (
                <>
                  <Button onClick={() => setModalSeparator(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparator}
                    onClose={() => setModalSeparator(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparator(false)}
                      selected={odontogramaSeparator}
                      setSelected={setOdontogramaSeparator}
                      subtitle="Separador"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Realizou a moldagem?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          performMolding === "NO" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClick("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          performMolding === "YES" ? "contained" : "outlined"
                        }
                        onClick={() => handlePerformMoldingClick("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>
                    {performMolding === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setMoldingJustification(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}

              {needsSeparator === "YES" && needsBand === "YES" && (
                <>
                  <Button onClick={() => setModalSeparator(true)}>
                    Abrir Odontograma
                  </Button>

                  <Modal
                    open={modalSeparator}
                    onClose={() => setModalSeparator(false)}
                  >
                    <Odontograma
                      onClose={() => setModalSeparator(false)}
                      selected={odontogramaSeparator}
                      setSelected={setOdontogramaSeparator}
                      subtitle="Separador"
                    />
                  </Modal>
                  <Styled.BoxDescription>
                    <Typography variant="h5">Instalou o separador?</Typography>
                    <Styled.BoxButtons>
                      <Button
                        variant={
                          installSeparator === "NO" ? "contained" : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClick("NO")}
                      >
                        NÃO
                      </Button>
                      <Button
                        variant={
                          installSeparator === "YES" ? "contained" : "outlined"
                        }
                        onClick={() => handleInstallSeparatorClick("YES")}
                      >
                        SIM
                      </Button>
                    </Styled.BoxButtons>

                    {installSeparator === "NO" && (
                      <TextField
                        label="Justifique"
                        onChange={(e) =>
                          setSeparatorJustification(e.target.value)
                        }
                      />
                    )}
                  </Styled.BoxDescription>
                </>
              )}

              {patientObj.aname_id > 0 ? (
                <Styled.BtnFixedAnam>
                  <Button fullWidth onClick={handleContinue}>
                    Continuar
                  </Button>
                </Styled.BtnFixedAnam>
              ) : (
                shouldShowBtnFixed() && (
                  <Styled.BtnFixed>
                    <Button
                      fullWidth
                      onClick={() => setStep(2)}
                      variant="outlined"
                    >
                      Voltar
                    </Button>

                    <Button fullWidth onClick={next}>
                      Continuar
                    </Button>
                  </Styled.BtnFixed>
                )
              )}
            </>
          )}
        </Styled.Box>
      )}
    </>
  );
};

export { ProcedurePerformedMovable };
