import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFirstConsult } from "shared/providers/FirstConsult";
import { FormProvider, useForm } from "react-hook-form";
import { HeadConsult } from "./Head";
import { Diagnosis } from "./Steps/Diagnosis";
import { Anamnese } from "./Steps/Anamnese";
import { ProcedurePerformedMovable } from "./Steps/ProceduresPerformed/movable";
//import { PlanningMovable } from "./Steps/Planning/movable";
import { Schedule } from "./Steps/Schedule";
import { PlanningFixed } from "./Steps/Planning/fixed";
import { PlanningMovable } from "./Steps/Planning/movable";
import { ProcedurePerformedFixed } from "./Steps/ProceduresPerformed/fixed";
import { ClinicalRecord } from "./Steps/ClinicalRecord";
import { useAuth } from "shared/providers/auth";
import QuestionModal from "components/Modal";
import { ProcedurePerformedInvisible } from "./Steps/ProceduresPerformed/invisible";
import { PlanningInvisible } from "./Steps/Planning/invisible";

interface IForm {
  diagnosis: string;
  surgeryNotAlign: boolean;
  treatmentSurgery: boolean;
  complementTreatment: string[];
  bracesType: string[];
}

const ConsultSchema = yup.object().shape({
  diagnosis: yup.string().required(),
  surgeryNotAlign: yup.boolean().required(),
  treatmentSurgery: yup.boolean().required(),
  complementTreatment: yup.array().required(),
  bracesType: yup.array().required(),
});

const ConsultStepper: React.FC = () => {
  const { step, patientObj, setStep } = useFirstConsult();
  const { setModalOpen, modalOpen, alerts } = useAuth();

  const methods = useForm<IForm>({
    resolver: yupResolver(ConsultSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (values: IForm) => {
    console.log(values);
  };

  const stepTitle = [
    { title: "[start] de [end] - Diagnóstico", max: 6, step: 1, firstPass: 1 },
    { title: "[start] de [end] - Anamnese", max: 6, step: 2, firstPass: 2 },
    {
      title: "[start] de [end] - Procedimentos Realizados",
      max: 6,
      step: 3,
      firstPass: 3,
    },

    {
      title: "[start] de [end] - Planejamento da Próxima Consulta",
      max: 6,
      step: 4,
      firstPass: 4,
    },
    { title: "[start] de [end] - Agendamento", max: 6, step: 5, firstPass: 5 },
    {
      title: "[start] de [end] - Ficha Clínica",
      max: 6,
      step: 6,
      firstPass: 6,
    },
  ];

  const stepAnamnese = [
    {
      title: "[start] de [end] - Procedimentos Realizados",
      max: 4,
      step: 3,
      firstPass: 1,
    },
    {
      title: "[start] de [end] - Planejamento da Próxima Consulta",
      max: 4,
      step: 4,
      firstPass: 2,
    },
    { title: "[start] de [end] - Agendamento", max: 4, step: 5, firstPass: 3 },
    {
      title: "[start] de [end] - Ficha Clínica",
      max: 4,
      step: 6,
      firstPass: 4,
    },
  ];

  const handleCloseModal = (
    responses: { id: string; description: string }[]
  ) => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    if (patientObj.aname_id > 0) {
      setStep(3);
    }
  }, [patientObj.aname_id, setStep]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadConsult
            title={patientObj.aname_id > 0 ? stepAnamnese : stepTitle}
          >
            {modalOpen && (
              <QuestionModal
                questions={alerts.map((alert) => ({
                  key: alert.key, // Passa a chave do alerta
                  message: alert.message, // Passa a mensagem do alerta
                }))}
                onClose={handleCloseModal}
              />
            )}

            {step === 1 && <Diagnosis control={control} />}
            {step === 2 && <Anamnese />}
            {step === 3 ? (
              patientObj.type === 2 ? (
                <ProcedurePerformedMovable />
              ) : patientObj.type === 3 ? (
                <ProcedurePerformedInvisible />
              ) : (
                <ProcedurePerformedFixed />
              )
            ) : (
              ""
            )}

            {step === 4 ? (
              patientObj.type === 2 ? (
                <PlanningMovable />
              ) : patientObj.type === 3 ? (
                <PlanningInvisible />
              ) : (
                <PlanningFixed />
              )
            ) : (
              ""
            )}

            {step === 5 && <Schedule />}
            {step === 6 && <ClinicalRecord />}
          </HeadConsult>
        </form>
      </FormProvider>
    </>
  );
};

export { ConsultStepper };
