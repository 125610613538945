import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
`;

export const FakeBox = styled.div`
  width: 62px;
  height: 56px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Menu = styled.div``;

export const Link = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  color: #343536;
  font-weight: 500;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const NoLink = styled.p`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  color: #343536;
  font-weight: 500;

  svg {
    width: 30px;
    height: 30px;
    color: rgb(5, 55, 121);
  }
`;
