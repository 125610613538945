import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  padding: 0 1px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxIndication = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #eeeff1;
  padding: 5px 15px;
  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #343536;
  }
`;

export const BoxButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  gap: 20px;
`;

export const BtnFixed = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 15px 0;
`;

export const BtnFixedAnam = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 15px 0;
`;

export const AriaBox = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 10px;
  gap: 10px;

  label {
    padding: 5px 8px;
    background-color: #f7f8fa;
    margin-left: 10px;
  }
`;

export const BoxPlanning = styled.div`
  border: 1px solid #007ded;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BoxDays = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 15px;
  margin: 10px 0;
`;
