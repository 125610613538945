import React from "react";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { FilterList } from "components/FilterList";
import { HeaderApp } from "components/Header";
import * as Styled from "./styles";
import { CardList } from "components/CardList";
import { useAuth } from "shared/providers/auth";
import { FaRegCalendarTimes } from "react-icons/fa";

const HomePage: React.FC = () => {
  const { scheduleData, loadingSchedule, showServed, controlArrived } =
    useAuth();

  // Filtrando os dados agendados
  const filteredScheduleData = scheduleData.filter((item) => {
    if (showServed) {
      // Se showServed for falso, remove os itens com st_schedule "K"
      const hasKSchedule = item.data?.some(
        (dataItem) => dataItem.st_schedule === "K"
      );
      if (hasKSchedule) {
        return false;
      }
    }

    // Se controlArrived for "ARRIVED", filtra apenas os dataItems com st_schedule "C"
    if (controlArrived === "ARRIVED") {
      const hasCSchedule = item.data?.some(
        (dataItem) =>
          dataItem.st_schedule === "C" || dataItem.st_schedule === "T"
      );
      return hasCSchedule; // Retorna true apenas se houver um dataItem com st_schedule "C"
    }

    // Continua a lógica do freeTime se controlArrived não for "ARRIVED"
    return true;
  });

  return (
    <>
      <HeaderApp />
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <FilterList />
        <Styled.ScrollList>
          {loadingSchedule ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : filteredScheduleData.length > 0 ? (
            filteredScheduleData.map((item, index) => (
              <CardList data={item} key={index} />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <FaRegCalendarTimes size={24} />
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                Nenhum agendamento encontrado.
              </Typography>
            </Box>
          )}
        </Styled.ScrollList>
      </Container>
    </>
  );
};

export { HomePage };
