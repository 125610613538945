import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import { Toaster, toast } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(window as any).showToast = (message: string) => {
  toast(message);
};

root.render(
  <React.StrictMode>
    <Toaster />
    <App />
  </React.StrictMode>
);
