import { IList } from "shared/types/List";

import { ReactComponent as TratamentoIcon } from "shared/assets/svg/tratamento.svg";
import { ReactComponent as FichaIcon } from "shared/assets/svg/ficha.svg";
import { ReactComponent as DocIcon } from "shared/assets/svg/doc.svg";
import { ReactComponent as EnceIcon } from "shared/assets/svg/encerrar.svg";
import { ROUTER } from "shared/constants/router";
import { ILabelValue } from "shared/types/Auth";
import { FaCamera } from "react-icons/fa";

export const ListMenu = [
  { text: "Sobre nós", href: "/about", blank: false },
  { text: "Dúvidas frequentes", href: "/", blank: false },
  { text: "Notificações", href: "/", blank: false },
  {
    text: "Política de privacidade",
    href: "https://www.orthodonticbrasil.com.br/politica-de-privacidade/",
    blank: true,
  },
  {
    text: "Termos de uso",
    href: "/https://www.orthodonticbrasil.com.br/termos-de-uso/",
    blank: true,
  },
];

export const phrases = [
  "Nascemos com o propósito de democratizar e modernizar o atendimento odontológico, por meio de um atendimento humanizado, de qualidade e com valores acessíveis.",

  "Temos mais de 20 anos de atuação e mais de 300 Clínicas espalhadas por todo o país.",

  "Já conquistamos mais de 7 milhões de Sorrisos!",

  "DEDICAÇÃO: Trabalhamos com profissionalismo e efetividade para que os objetivos de nossos clientes sejam alcançados.",

  "QUALIDADE: Desenvolvemos um trabalho altamente qualificado, com profissionais competentes e especializados, com os melhores materiais disponíveis no mercado odontológico, sem deixar de lado o atendimento humanizado.",

  "INTEGRIDADE: Trabalhamos com inteireza de caráter, lealdade e transparência, para conquistarmos e mantermos a confiança e o respeito de nossos clientes e parceiros.",

  "UNIÃO E SOLIDEZ: Acreditamos que o trabalho, em conjunto com nossos parceiros, formando uma Rede cada vez maior, nos destaca com solidez no mercado.",

  "EMPATIA: A empatia leva as pessoas a ajudarem umas às outras. Está intimamente ligada ao altruísmo - amor e interesse pelo próximo - e à capacidade de ajudar. Aplicar este conceito no nosso dia a dia, nos destaca como referência em atendimento de qualidade e satisfação de clientes.",
];

export const listData: IList[] = [
  {
    hour: "7:30 - 8:00",
    readInit: "V",
    situation: "Horário Vago",
  },
  {
    hour: "8:30",
    situation: "Chegou",
    id: 64123567,
    name: "Camille Landa",
    readInit: "K",
    typeConsult: "Primeira Consulta",
    actions: ["Documentação", "Ficha Clínica", "Odontograma"],
    picture:
      "https://s3-alpha-sig.figma.com/img/ced1/c2b6/5e30cdb56f4aaf1c5b1ec40727c96630?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kq~toFxHw5v2O4e5aWqBSyukt3xIsKm6g1gNh0IrGr0uYJm5WMsWzqiyfwE3F61SE~3gYVWaPASvi1IhyWhLGPVepuefJQDK-oHZyjZWvGNKoIyH6a8ZI51cgoZMaAQUKJ3zQ9j~coajeekacL5zLHV9j8rYXS0UY2PsWUyVQ99bR8945vmeoVWPR2pJsBgs-csKkeF43pxIE2dCbYW3WYNJ~585mXKehxswmh317gzpATFd7Il14XQZCYiu7kOOcIx4HJeOpSMMLoiweHAd9fCeERBSjHNPP0OXiFRjME8sTes8-jyqtEAnczB-nviEiscve1HbsmhMUjUddwv3RQ__",
    typeBrace: "Movable",
  },
  {
    hour: "9:00",
    situation: "Chegou",
    id: 64123567,
    name: "Ana Clara",
    readInit: "K",
    typeConsult: "Colagem",
    actions: ["Documentação", "Ficha Clínica", "Odontograma"],
    picture:
      "https://s3-alpha-sig.figma.com/img/ae16/b440/14b8d1e8d19226d301d7d2a82f580040?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=adzvgu~1xhgAyfv3wfa~hg4pp86CBq8smSXnehaAVJx0nLt-sbD2NNWrYftDlTHXa94nq3iUATNSY07~Cliw~xOelR6QLEjhw~8DDt1VCc7S29Oy8RetJ6Ls-IymHQnJ0lQjZF1IrnDHIIrOicZr5-gzJ5b~nXU4lk7YtAGfhRqiRR8Vk9-yxiWhD29LMzx3iHn773fdchmLI43msZ2bdFE9kXJRNmU8pSdNToMobzpnoeTe7KLyjxjA7fCMDcQAeI0WtSWHFbaOVNExSC3Y69CQBUnO2Fuww-naYDF1v~YT8Nda4XhV5QSKJt0z-OQ-G4YBsg1SxH1rMg5jdUH5xw__",
  },
  {
    hour: "9:15",
    situation: "Aguardando",
    id: 64123567,
    name: "Vitor Silva",
    readInit: "T",
    typeConsult: "Restauração",
    actions: ["Documentação", "Ficha Clínica", "Odontograma"],
    picture:
      "https://s3-alpha-sig.figma.com/img/6a28/0694/86f3668891db7b259ffb1eaac5ffe5a8?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=luWRQP7~U61XaMSMT0PqqYMwSTJhWPiCSSzD~t5FmiJI5HGso5n6HzHSL3UGaUPG1tdngmFt-CXl2p1x~Kda3gJgS~siqlcbmQQyEDY3UeIM1asIjJWcvKTQc665jQZZJYN92zOCEi~lf3z4QyG~2ID2sZ8F-~OpWu4uAS95DI89Z3OV6bFIUN55Y5HbtXxXiMxHnOVlYYHKP1q2bErire8YyWVE~9axXWOODHZ9xmlonPZ5~8m723HTcqOwwP5RoMdiZTIJ62DOTOpy0XZM9c3t875nCactUF-BOfMwA-ow1oOcVHVDDWJyblUNKMrGXBlSVj7NxcYUsaE3RM5~0Q__",
  },
  {
    hour: "9:30",
    situation: "Chegou",
    id: 64123567,
    name: "Lucas Moura",
    readInit: "K",
    typeConsult: "Segunda Consulta",
    actions: ["Documentação", "Ficha Clínica", "Odontograma"],
    picture:
      "https://s3-alpha-sig.figma.com/img/6a28/0694/86f3668891db7b259ffb1eaac5ffe5a8?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=luWRQP7~U61XaMSMT0PqqYMwSTJhWPiCSSzD~t5FmiJI5HGso5n6HzHSL3UGaUPG1tdngmFt-CXl2p1x~Kda3gJgS~siqlcbmQQyEDY3UeIM1asIjJWcvKTQc665jQZZJYN92zOCEi~lf3z4QyG~2ID2sZ8F-~OpWu4uAS95DI89Z3OV6bFIUN55Y5HbtXxXiMxHnOVlYYHKP1q2bErire8YyWVE~9axXWOODHZ9xmlonPZ5~8m723HTcqOwwP5RoMdiZTIJ62DOTOpy0XZM9c3t875nCactUF-BOfMwA-ow1oOcVHVDDWJyblUNKMrGXBlSVj7NxcYUsaE3RM5~0Q__",
  },
  {
    hour: "9:45 - 10:00",
    readInit: "V",
    situation: "Horário Vago",
  },

  {
    hour: "10:30",
    situation: "Chegou",
    id: 64123567,
    name: "Nicolas Silva",
    readInit: "K",
    typeConsult: "Primeira Consulta",
    actions: ["Documentação", "Ficha Clínica", "Odontograma"],
    picture:
      "https://s3-alpha-sig.figma.com/img/ced1/c2b6/5e30cdb56f4aaf1c5b1ec40727c96630?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kq~toFxHw5v2O4e5aWqBSyukt3xIsKm6g1gNh0IrGr0uYJm5WMsWzqiyfwE3F61SE~3gYVWaPASvi1IhyWhLGPVepuefJQDK-oHZyjZWvGNKoIyH6a8ZI51cgoZMaAQUKJ3zQ9j~coajeekacL5zLHV9j8rYXS0UY2PsWUyVQ99bR8945vmeoVWPR2pJsBgs-csKkeF43pxIE2dCbYW3WYNJ~585mXKehxswmh317gzpATFd7Il14XQZCYiu7kOOcIx4HJeOpSMMLoiweHAd9fCeERBSjHNPP0OXiFRjME8sTes8-jyqtEAnczB-nviEiscve1HbsmhMUjUddwv3RQ__",
    typeBrace: "Fixed",
  },
];

export const ComplementData = [
  "Periodontal",
  "Protético",
  "Implantes",
  "Restaurador",
  "Cirúrgico",
  "Estético/Cosmético",
  "Outros",
];

export const menuPatient = [
  { icon: FaCamera, text: "Tirar Foto", href: "" },
  { icon: TratamentoIcon, text: "Plano de Tratamento", href: "/TRATAMENTO" },
  { icon: FichaIcon, text: "Ficha Clínica", href: "/FICHA" },
  { icon: DocIcon, text: "Documentação", href: "/DOC" },
  { icon: EnceIcon, text: "Encerrar atendimento", href: ROUTER.CLOSE_CONSULT },
];

export const menuPatientAvaliation = [
  { icon: EnceIcon, text: "Encerrar atendimento", href: ROUTER.CLOSE_CONSULT },
];

export const procedureOptions: ILabelValue[] = [
  { label: "", value: "" },
  { label: "Manutenção", value: "Maintenance" },
  { label: "Alta", value: "High" },
  { label: "Banda", value: "Band" },
  { label: "Colagem de Tubo", value: "Tube Bonding" },
  {
    label: "Colagem de aparatologia ortodôntica",
    value: "Orthodontic Appliance Bonding",
  },
  { label: "Separador", value: "Separator" },
  { label: "Colagem de Botão", value: "Button Bonding" },
  { label: "Controle de Contenção", value: "Retention Control" },
  { label: "Controle de Erupção", value: "Eruption Control" },
  { label: "Levante de Mordida", value: "Bite Raise" },
  { label: "Recolagem Contenção", value: "Retention Recementation" },
  {
    label: "Remoção de Aparatologia Ortodôntica",
    value: "Orthodontic Appliance Removal",
  },
  {
    label: "Instalação de elásticos intermaxilares",
    value: "Intermaxillary Elastics Installation",
  },
  { label: "Mini Implante", value: "Mini Implant" },
  { label: "Remoção mini implante", value: "Mini Implant Removal" },
];

export const referralOptions: ILabelValue[] = [
  {
    value: 1,
    label: "Periodontal - ORTO 42",
  },
  {
    value: 2,
    label: "Protético - ORTO 42",
  },
  {
    value: 3,
    label: "Implantes - ORTO 42",
  },
  {
    value: 4,
    label: "Restaurador - ORTO 42",
  },
  {
    value: 5,
    label: "Cirúrgico - ORTO 42",
  },
  {
    value: 6,
    label: "Estético/ Cosmético - ORTO 42",
  },
  {
    value: 7,
    label: "Extração - ORTO 11",
  },
  {
    value: 8,
    label: "Mini-Implante - ORTO 11",
  },
  {
    value: 9,
    label: "Raspagem - ORTO 11",
  },
  {
    value: 10,
    label: "Receituário Normal - ORTO 22N",
  },
  {
    value: 11,
    label: "Instruções de uso de aparelho complementar - ORTO 40",
  },
  {
    value: 12,
    label: "Ciência de indicação cirurgica - ORTO 41",
  },
];

export const materialOptions: ILabelValue[] = [
  { label: "Aço", value: "Aço" },
  { label: "Niti", value: "Niti" },
  { label: "Aço Curva Reversa", value: "Aço Curva Reversa" },
  { label: "Niti Curva Reversa", value: "Niti Curva Reversa" },
];

export const numbers: ILabelValue[] = [
  { label: "12 Redondo", value: "12 Redondo" },
  { label: "14 Redondo", value: "14 Redondo" },
  { label: "16 Redondo", value: "16 Redondo" },
  { label: "18 Redondo", value: "18 Redondo" },
  { label: "20 Redondo", value: "20 Redondo" },
  { label: "16x22 Retangular", value: "16x22 Retangular" },
  { label: "17x25 Retangular", value: "17x25 Retangular" },
  { label: "18x25 Retangular", value: "18x25 Retangular" },
  { label: "19x25 Retangular", value: "19x25 Retangular" },
];
