import React from "react";
import * as Styled from "./styles";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { getTimeTable } from "requests/Time";
import { useAuth } from "shared/providers/auth";
import { useMaintenance } from "shared/providers/Maintenance";
import dayjs, { Dayjs } from "dayjs";
import toast from "react-hot-toast";

interface AvailableDates {
  [date: string]: string[];
}

// Define a interface para o objeto de resposta da API
interface ApiResponse {
  bestDay: string;
  availableDates: AvailableDates;
  separetor: { idDent: number };
}

// Define a interface para o objeto transformado
interface TransformedDate {
  date: string;
  hours: string[];
}

const Schedule: React.FC = () => {
  const {
    setStep,
    selectedDate,
    selectedTime,
    selectedHour,
    handleDateChange,
    handleTimeChange,
    handleHourChange,
    bestTime,
    setBestTime,
    //setSelectedHour,
    mostImportantType,
    setMostImportantType,
    setBandaExists,
    setBandaId,
    bandaExists,
    selectedDateBand,
    selectedHourBand,
    selectedTimeBand,
    handleDateChangeBand,
    handleTimeChangeBand,
    handleHourChangeBand,
    setSelectedProceduresId,
    setSeparatorId,
    aparate,
    aparateBrace,
    glue,
    glueTube,
    wire,
    changeWire,
    ciment,
    cimentBand,
    keep,
    keepThread,
    valueOthers,
    patientObj,
    needsBand,
    complement,
    hoursTime,
    setHoursTime,
    selectedProceduresId,
    bestTimeBand,
    setBestTimeBand,
  } = useMaintenance();
  const { user } = useAuth();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [apiResponse, setApiResponse] = React.useState<ApiResponse>(
    {} as ApiResponse
  );

  const [apiResponseBand, setApiResponseBand] = React.useState<ApiResponse>(
    {} as ApiResponse
  );

  const orderByImportance = React.useCallback(() => {
    const importanceOrder: number[] = [
      55, 56, 2, 285, 30, 31, 7, 201, 290, 294, 295, 492, 136, 127, 128, 491,
      65, 490, 134, 138, 440, 266,
    ];

    const typeMap: Record<number, string> = {
      55: "Colagem Inferior",
      56: "Colagem Superior",
      2: "1º Consulta",
      285: "Colagem de Tubo",
      30: "Banda Inferior",
      31: "Banda Superior",
      7: "Alta",
      201: "Remoção de Aparelho",
      290: "Instalação de Contenção Superior e Inferior",
      294: "Instalação de Contenção Superior",
      295: "Instalação de Contenção Inferior",
      492: "Moldagem do Aparelho Complementar",
      136: "Manutenção",
      127: "Instalação Aparelho Móvel Inferior",
      128: "Instalação Aparelho Móvel Superior",
      491: "Manutenção",
      65: "Controle de Contenção",
      490: "Manutenção",
      134: "Manutenção",
      138: "Manutenção",
      440: "Manutenção",
      266: "Manutenção",
    };

    const initialValues: number[] = [];
    let newValues: number[] = [...initialValues];

    if (patientObj.type === 3) {
      newValues = [...newValues, 136];
    }

    if (patientObj.type === 2) {
      if (needsBand === "YES") {
        newValues = [...newValues, 136];
      }
    }

    if (aparate) {
      if (aparateBrace.includes("SUP")) {
        newValues = [...newValues, 2, 56];
      } else if (aparateBrace.includes("INF")) {
        newValues = [...newValues, 2, 55];
      }
    }

    if (glue) {
      if (glueTube.includes("INF") || glueTube.includes("SUP")) {
        newValues = [...newValues, 285];
      }
    }

    if (wire) {
      if (changeWire.includes("INF") || changeWire.includes("SUP")) {
        newValues = [...newValues, 136];
      }
    }

    if (keepThread) {
      if (keep.includes("INF") || keep.includes("SUP")) {
        newValues = [...newValues, 136];
      }
    }

    if (ciment) {
      if (cimentBand.includes("SUP")) {
        newValues = [...newValues, 31];
      } else if (cimentBand.includes("INF")) {
        newValues = [...newValues, 30];
      }
    }

    if (complement) {
      newValues = [...newValues, 136];
    }

    if (valueOthers.length > 0) {
      valueOthers.map(
        (item: { value: number }) => (newValues = [...newValues, item.value])
      );
    }

    newValues.sort(
      (a: any, b: any) =>
        importanceOrder.indexOf(a) - importanceOrder.indexOf(b)
    );

    const mostImportantId: number | undefined = newValues[0];

    setMostImportantType(
      mostImportantId !== undefined ? typeMap[mostImportantId] : ""
    );

    setSelectedProceduresId(mostImportantId);

    const bandaIds: number[] = newValues.filter((id) => {
      const type = typeMap[id];
      return type === "Banda Inferior" || type === "Banda Superior";
    });

    if (bandaIds.length > 0) {
      setBandaExists(true);
      setBandaId(244);
    } else {
      setBandaExists(false);
      setBandaId(0);
    }

    if (patientObj.type === 2) {
      setBandaExists(false);
      setBandaId(0);
    }
  }, [
    aparate,
    aparateBrace,
    changeWire,
    ciment,
    cimentBand,
    complement,
    glue,
    glueTube,
    keep,
    keepThread,
    needsBand,
    patientObj.type,
    setBandaExists,
    setBandaId,
    setMostImportantType,
    setSelectedProceduresId,
    valueOthers,
    wire,
  ]);

  const onNext = () => {
    if (!selectedDate) {
      toast.error("Selecione a data");
      return false;
    }

    if (!selectedTime) {
      toast.error("Selecione o tempo de consulte");
      return false;
    }

    if (!selectedHour) {
      toast.error("Selecione o horário da consulta");
      return false;
    }

    if (bandaExists) {
      if (!selectedDateBand) {
        toast.error("Selecione a data do Separador");
        return false;
      }

      if (!selectedTimeBand) {
        toast.error("Selecione o tempo de consulte do Separador");
        return false;
      }

      if (!selectedHourBand) {
        toast.error("Selecione o horário da consulta do Separador");
      }
    }

    setStep(4);
  };

  function convertDateToAmerican(dateString: string): string {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${month}/${day}/${year}`;
  }

  function transformAvailableDates(data: ApiResponse): TransformedDate[] {
    const availableDates = data.availableDates;
    const result: TransformedDate[] = [];

    // Itera sobre cada chave (data) em availableDates
    for (const date in availableDates) {
      if (availableDates.hasOwnProperty(date)) {
        // Converte a data para o padrão americano
        const americanDate = convertDateToAmerican(date);

        // Cria um objeto para cada data com suas horas
        const hours = availableDates[date];
        result.push({
          date: americanDate,
          hours: hours,
        });
      }
    }

    return result;
  }

  const MyDatePicker: React.FC<{
    apiResponse: ApiResponse;
    selectDatePicker: dayjs.Dayjs | null;
    handleDateChangePicker: (value: dayjs.Dayjs | null) => void;
    bestTime: string;
  }> = ({
    apiResponse,
    handleDateChangePicker,
    selectDatePicker,
    bestTime,
  }) => {
    const transformedDates = transformAvailableDates(apiResponse);

    // Cria um conjunto de datas disponíveis
    const availableDateSet = new Set<string>(
      transformedDates.map((dateObj: { date: any }) => dateObj.date) // Apenas as datas no formato MM/DD/YYYY
    );

    // Função para desabilitar datas que não estão disponíveis
    const shouldDisableDate = (date: Dayjs) => {
      const formattedDate = date.format("MM/DD/YYYY");
      return !availableDateSet.has(formattedDate); // Verifica se a data está no conjunto
    };

    // Função para obter as horas da data selecionada
    const getHoursForSelectedDate = (
      selectedDate: Dayjs | null
    ): string[] | null => {
      if (!selectedDate) return null;

      const formattedDate = selectedDate.format("MM/DD/YYYY");
      const matchingDateObj = transformedDates.find(
        (dateObj) => dateObj.date === formattedDate
      );

      if (matchingDateObj) {
        return matchingDateObj.hours.map((hour) =>
          dayjs(`2023-01-01 ${hour}`).format("HH:mm")
        );
      }

      return null;
    };

    // Função para lidar com a mudança de data
    const handleDateChange = (newValue: Dayjs | null) => {
      handleDateChangePicker(newValue);

      // Obter as horas da data selecionada usando o callback
      const hours = getHoursForSelectedDate(newValue);
      if (hours) {
        setHoursTime(hours);
      } else {
        console.log("Nenhuma hora disponível para a data selecionada.");
      }
    };

    const bestTimeParsed = dayjs(bestTime, "YYYY-MM-DD");

    React.useEffect(() => {
      if (bestTimeParsed.isValid() && !selectDatePicker) {
        // Simula a seleção da data bestTimeParsed, formatando-a para DD/MM/YYYY

        handleDateChange(bestTimeParsed);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bestTimeParsed, selectDatePicker, handleDateChangePicker]);

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <DatePicker
          label="Selecione a data"
          value={selectDatePicker || bestTimeParsed}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          minDate={dayjs(new Date())}
          shouldDisableDate={shouldDisableDate} // Desabilita as datas que não estão disponíveis
        />
      </LocalizationProvider>
    );
  };

  React.useEffect(() => {
    orderByImportance();
  }, [orderByImportance]);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();
  const callBestDate = React.useCallback(async () => {
    setLoading(true);
    const { data, status } = await getTimeTable({
      id: user.data.id,
      date: currentDate,
      interval: `${selectedTime ? selectedTime : 15}`,
      separator: bandaExists ? 1 : 0,
      idProcedure: selectedProceduresId,
      idDent: patientObj.idDent_rel ? patientObj.idDent_rel : patientObj.idDent,
    });

    if (data.data.separetor) {
      setSeparatorId(data.data.separetor.idDent);
    }

    if ([200, 201].includes(status)) {
      setApiResponse(data.data);
      setBestTime(data.data.bestDay);
      setLoading(false);
    } else {
      toast.error(data.message);
    }
  }, [
    user.data.id,
    currentDate,
    selectedTime,
    bandaExists,
    selectedProceduresId,
    patientObj.idDent_rel,
    patientObj.idDent,
    setSeparatorId,
    setBestTime,
  ]);

  React.useEffect(() => {
    callBestDate();
  }, [callBestDate]);

  const callBestDateBand = React.useCallback(async () => {
    const dateStrBand: string = String(selectedDate);

    const dateBand: Date = new Date(dateStrBand);

    const optionsBand: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "America/Sao_Paulo",
    };

    const formattedDateBand: string = dateBand.toLocaleDateString(
      "pt-BR",
      optionsBand
    );

    const formattedDateUTCBand = () => {
      const date = formattedDateBand.split("/");

      return `${date[2]}-${date[1]}-${date[0]}`;
    };
    setLoading(true);
    const { data, status } = await getTimeTable({
      id: user.data.id,
      date: formattedDateUTCBand(),
      interval: `${selectedTimeBand ? selectedTimeBand : 15}`,
      separator: 1,
      idProcedure: selectedProceduresId,
      idDent: patientObj.idDent_rel ? patientObj.idDent_rel : patientObj.idDent,
    });

    if ([200, 201].includes(status)) {
      setApiResponseBand(data.data);
      setBestTimeBand(data.data.bestDay);
      setLoading(false);
    } else {
      toast.error(data.message);
    }
  }, [
    selectedDate,
    user.data.id,
    selectedTimeBand,
    selectedProceduresId,
    patientObj.idDent_rel,
    patientObj.idDent,
    setBestTimeBand,
  ]);

  React.useEffect(() => {
    if (bandaExists && selectedTimeBand) {
      callBestDateBand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeBand]);

  /*React.useEffect(() => {
    if (bestTime && bestTime.bestDate && bestTime.bestDate.hours) {
      setSelectedHour(bestTime.bestDate.hours);
    }
  }, [bestTime, setSelectedHour]);*/

  const [isSelectHourAgain, setIsSelectHourAgain] =
    React.useState<boolean>(false);

  const transformedDates = transformAvailableDates(apiResponse);

  // Função para obter as horas da data selecionada
  const getHoursForSelectedDate = React.useCallback(
    (selectedDate: Dayjs | null): string[] | null => {
      if (!selectedDate) return null;

      const formattedDate = selectedDate.format("MM/DD/YYYY");
      const matchingDateObj = transformedDates.find(
        (dateObj) => dateObj.date === formattedDate
      );

      if (matchingDateObj) {
        return matchingDateObj.hours.map((hour) =>
          dayjs(`2023-01-01 ${hour}`).format("HH:mm")
        );
      }

      return null;
    },
    [transformedDates]
  );

  const handleGetHours = React.useCallback(
    (newValue: Dayjs | null) => {
      // Obter as horas da data selecionada usando o callback
      const hours = getHoursForSelectedDate(newValue);
      if (hours) {
        setHoursTime(hours);
      } else {
        console.log("Nenhuma hora disponível para a data selecionada.");
      }
    },
    [getHoursForSelectedDate, setHoursTime]
  );

  React.useEffect(() => {
    if (selectedTime && selectedDate && isSelectHourAgain) {
      handleGetHours(selectedDate);
    }
  }, [handleGetHours, isSelectHourAgain, selectedDate, selectedTime]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Styled.Box>
          <>
            {bandaExists ? (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Agendamento - {mostImportantType}
                </Typography>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-time-label">
                    Tempo da consulta
                  </InputLabel>
                  <Select
                    labelId="select-time-label"
                    value={selectedTime}
                    onChange={(e) => {
                      handleTimeChange(e);
                      setIsSelectHourAgain(true);
                      setTimeout(() => {
                        setIsSelectHourAgain(false);
                      }, 1000);
                    }}
                    label="Tempo da consulta"
                  >
                    <MenuItem value={15}>15 minutos</MenuItem>
                    <MenuItem value={20}>20 minutos</MenuItem>
                    <MenuItem value={30}>30 minutos</MenuItem>
                    <MenuItem value={45}>45 minutos</MenuItem>
                    <MenuItem value={60}>1 hora</MenuItem>
                  </Select>
                </FormControl>

                {selectedTime && (
                  <MyDatePicker
                    apiResponse={apiResponse}
                    handleDateChangePicker={handleDateChange}
                    selectDatePicker={selectedDate}
                    bestTime={bestTime}
                  />
                )}
                {selectedTime && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-hour-label">
                      Horário disponível
                    </InputLabel>
                    <Select
                      labelId="select-hour-label"
                      value={selectedHour}
                      onChange={handleHourChange}
                      label="Horário disponível"
                    >
                      {/*bestTime &&
                      bestTime.bestDate &&
                      bestTime.bestDate.hours && (
                        <MenuItem key="best" value={bestTime.bestDate.hours}>
                          {bestTime.bestDate.hours} (Recomendado)
                        </MenuItem>
                      )*/}
                      {hoursTime &&
                        hoursTime.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {selectedHour && (
                  <Styled.BoxDescription>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Procedimento
                    </Typography>

                    <Typography>{mostImportantType}</Typography>
                  </Styled.BoxDescription>
                )}
                {selectedHour && (
                  <>
                    <Divider />
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Agendamento - Separador
                    </Typography>

                    <FormControl fullWidth margin="normal">
                      <InputLabel id="select-time-label">
                        Tempo da consulta
                      </InputLabel>
                      <Select
                        labelId="select-time-label"
                        value={selectedTimeBand}
                        onChange={handleTimeChangeBand}
                        label="Tempo da consulta"
                      >
                        <MenuItem value={15}>15 minutos</MenuItem>
                        <MenuItem value={20}>20 minutos</MenuItem>
                        <MenuItem value={30}>30 minutos</MenuItem>
                        <MenuItem value={45}>45 minutos</MenuItem>
                        <MenuItem value={60}>1 hora</MenuItem>
                      </Select>
                    </FormControl>

                    {selectedTimeBand && (
                      <MyDatePicker
                        apiResponse={apiResponseBand}
                        handleDateChangePicker={handleDateChangeBand}
                        selectDatePicker={selectedDateBand}
                        bestTime={bestTimeBand}
                      />
                    )}
                    {selectedTimeBand && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="select-hour-label">
                          Horário disponível
                        </InputLabel>
                        <Select
                          labelId="select-hour-label"
                          value={selectedHourBand}
                          onChange={handleHourChangeBand}
                          label="Horário disponível"
                        >
                          {/*bestTime &&
                          bestTime.bestDate &&
                          bestTime.bestDate.hours && (
                            <MenuItem
                              key="best"
                              value={bestTime.bestDate.hours}
                            >
                              {bestTime.bestDate.hours} (Recomendado)
                            </MenuItem>
                          )*/}
                          {hoursTime &&
                            hoursTime.map((hour) => (
                              <MenuItem key={hour} value={hour}>
                                {hour}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    {selectedTimeBand && (
                      <Styled.BoxDescription>
                        <Typography sx={{ fontWeight: "bold !important" }}>
                          Procedimento
                        </Typography>

                        <Typography>Separador</Typography>
                      </Styled.BoxDescription>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Agendamento - {mostImportantType}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-time-label">
                    Tempo da consulta
                  </InputLabel>
                  <Select
                    labelId="select-time-label"
                    value={selectedTime}
                    onChange={(e) => {
                      handleTimeChange(e);
                      setIsSelectHourAgain(true);
                      setTimeout(() => {
                        setIsSelectHourAgain(false);
                      }, 1000);
                    }}
                    label="Tempo da consulta"
                  >
                    <MenuItem value={15}>15 minutos</MenuItem>
                    <MenuItem value={20}>20 minutos</MenuItem>
                    <MenuItem value={30}>30 minutos</MenuItem>
                    <MenuItem value={45}>45 minutos</MenuItem>
                    <MenuItem value={60}>1 hora</MenuItem>
                  </Select>
                </FormControl>
                {selectedTime && (
                  <MyDatePicker
                    apiResponse={apiResponse}
                    handleDateChangePicker={handleDateChange}
                    selectDatePicker={selectedDate}
                    bestTime={bestTime}
                  />
                )}
                {selectedDate && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="select-hour-label">
                      Horário disponível
                    </InputLabel>
                    <Select
                      labelId="select-hour-label"
                      value={selectedHour}
                      onChange={handleHourChange}
                      label="Horário disponível"
                    >
                      {/*bestTime &&
                        bestTime.bestDate &&
                        bestTime.bestDate.hours && (
                          <MenuItem key="best" value={bestTime.bestDate.hours}>
                            {bestTime.bestDate.hours} (Recomendado)
                          </MenuItem>
                        )*/}
                      {hoursTime &&
                        hoursTime.map((hour) => (
                          <MenuItem key={hour} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}

                {selectedHour && (
                  <Styled.BoxDescription>
                    <Typography sx={{ fontWeight: "bold !important" }}>
                      Procedimento
                    </Typography>

                    <Typography>{mostImportantType}</Typography>
                  </Styled.BoxDescription>
                )}
              </>
            )}
          </>

          {selectedHour ? (
            <>
              <Styled.BtnFixed>
                <Button fullWidth onClick={() => setStep(2)} variant="outlined">
                  Voltar
                </Button>
                <Button fullWidth onClick={onNext}>
                  Continuar
                </Button>
              </Styled.BtnFixed>
            </>
          ) : (
            <Styled.BtnFixedAnam>
              <Button fullWidth onClick={() => setStep(2)} variant="outlined">
                Voltar
              </Button>
            </Styled.BtnFixedAnam>
          )}
        </Styled.Box>
      )}
    </>
  );
};

export { Schedule };
